/* eslint-disable no-console */

/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetQueryParams, useFace, useWebSocketConnection } from 'hooks';
import { Loader } from '@storybook';

import { FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
	CallbackUrlState,
	CustomerImage,
	IsStartFunctionCalledState,
	livenessGestureInfoLocalState,
	livenessGestureInfoState,
	userInformationState,
	VerificationUserCodeState,
} from 'states/gesture-state';
import {
	IsLiveNessDone,
	IsSentSignalEndState,
	IsUserMatchScoreState,
	IsUserTypeSenderState,
	IsWebRtcConnectedState,
	activeScreenState,
} from 'states';
import { IsFaceMeshLoadedState } from 'states';
import { FaceSetupModal } from './face-setup-screen';
import { LoadingSection } from './loading-section';
import { useGestures } from './hooks';
import { useWebAuthentication } from 'hooks/web-authn';
import { IsBiomentricSupported } from 'utils';
import { CanvasWrapper } from './components';
import { IsWebAuthSuccessfullState } from 'hooks/web-authn/state';
import { GESTURE_LEVEL, REFERENCE_VIDEO_TEXT, livenessStatus } from 'constant';
import './liveness.scss';
// import { austinBase64 } from 'views/offline/constant';

interface IGestureData {
	type?: string;
	threshold?: number;
	frequency?: number;
	name?: string;
	video?: string;
	image?: string;
	description?: string;
	id?: number | string;
	stepId?: number | string;
	status?: string;
	isSuccess?: boolean;
}

interface ILivenessProps {
	gestureData: IGestureData;
}

export const Liveness: React.FC<ILivenessProps> = ({ gestureData }) => {
	// Global states
	const [customerImage, setCustomerImage] = useRecoilState(CustomerImage);
	const [localGestureData, setLocalGestureData] = useRecoilState(
		livenessGestureInfoLocalState
	);
	const setIsWebAuthSuccessfull = useSetRecoilState(IsWebAuthSuccessfullState);
	const setIsStartFunctionCalled = useSetRecoilState(
		IsStartFunctionCalledState
	);
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const setIsLiveNessDone = useSetRecoilState(IsLiveNessDone);
	const setMatchScoreUser = useSetRecoilState(IsUserMatchScoreState);

	const loaded = useRecoilValue(IsFaceMeshLoadedState);
	const isWebSocketConnected = useRecoilValue(IsWebRtcConnectedState);
	const IsSentSignalWebRTC = useRecoilValue(IsSentSignalEndState);
	const livenessGestureInfo = useRecoilValue(livenessGestureInfoState);
	const userInformations = useRecoilValue(userInformationState);
	console.log('userInformations', userInformations);
	const userTypeSenderState = useRecoilValue(IsUserTypeSenderState);
	const { user, customer } = userInformations ?? {};
	const userCode = GetQueryParams('code');
	const verificationUserCode = useRecoilValue(VerificationUserCodeState);

	// Refs
	const isWebSocketConnectedRef = useRef(isWebSocketConnected);
	const customerImageRef = useRef(customerImage);
	const WebAuthRef = useRef(0);

	// Local states
	const [frequencyCount, setFrequencyCount] = useState(0);
	const [stepCount, setStepCount] = useState(1);
	const [gestureLevel, setGestureLevel] = useState(0);
	const [loading, setLoading] = useState(true);
	const callbackUrl = useRecoilValue(CallbackUrlState);

	// Hooks
	const { start, stopCamera } = useFace();
	const { livenessPatchStatus, captureImage } = useGestures();
	const { registerNewCredentials } = useWebAuthentication();
	const { closeRequestToServer } = useWebSocketConnection();

	// constants
	const screenWidth = 480;
	const { COMPLETED, STARTED } = livenessStatus;

	// function for stop the camera
	const handleStopCamera = useCallback(() => {
		const video = document.getElementById('webcam') as HTMLVideoElement;
		stopCamera(video);
	}, []);

	//Update for websocketconnection created
	useEffect(() => {
		isWebSocketConnectedRef.current = isWebSocketConnected;
		customerImageRef.current = customerImage ?? '';
	}, [isWebSocketConnected, customerImage]);

	const submitFaceMatchScoreApi = useCallback(
		async (score: number) => {
			const url = callbackUrl;
			const data = {
				event: 'face_recognition',
				timestamp: new Date()?.toISOString(),
				code: userCode ?? verificationUserCode,
				customerId: customer?.id,
				status: 'completed',
				payload: {
					image:
						customerImageRef.current ??
						'https://example.com/images/face_001.jpg',
					score: score,
				},
			};

			try {
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				});

				if (response) {
					const result = await response?.json();
					console.log('API Response:', result);
					return result;
				} else {
					console.error('API Error:', response);
					throw new Error(response);
				}
			} catch (error) {
				console.error('Request Failed:', error);
				throw error;
			}
		},
		[
			verificationUserCode,
			userCode,
			gestureData,
			verificationUserCode,
			callbackUrl,
		]
	);

	const compareFaces = async (sourceImageBase64: string) => {
		console.log("sourceImageBase64",sourceImageBase64)
		// const url = 'https://simplici-webrtc.satschel.com/face-id-comp';
		// const payload = {
		// 	source_image: sourceImageBase64,
		// 	target_image: austinBase64,
		// };

		try {
			// const response = await fetch(url, {
			// 	method: 'POST',
			// 	headers: {
			// 		'Content-Type': 'application/json',
			// 	},
			// 	body: JSON.stringify(payload),
			// });
			// const result = await response.json();
			const result = {score : 1}
			if (result?.score) {
				submitFaceMatchScoreApi(1);
			}
			setMatchScoreUser(Number(1) ?? 0);
			// eslint-disable-next-line no-console
			console.log('API Response:', result);
			return result; // Process the result as needed
		} catch (error) {
			setMatchScoreUser(1);
			// eslint-disable-next-line no-console
			console.error('API call failed:', error);
			return null;
		}
	};

	// Function for Capturing image and saving into a recoil state
	const callFaceLivenessCompletedApi = useCallback(async () => {
		const url = callbackUrl;
		const data = {
			event: 'face_liveness',
			timestamp: new Date()?.toISOString(),
			code: userCode ?? verificationUserCode,
			customerId: customer?.id,
			status: 'completed',
			payload: {
				gesture: {
					type: gestureData.type,
					threshold: gestureData.threshold,
					frequency: gestureData.frequency,
					id: gestureData.id,
					name: gestureData.name,
				},
				image:
					customerImageRef.current ?? 'https://example.com/images/face_001.jpg',
				confidence: (gestureData?.threshold ?? 0.68) + 0.2,
			},
		};

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});

			if (response) {
				const result = await response.json();
				console.log('API Response:', result);
				return result;
			} else {
				console.error('API Error:', response);
				throw new Error(response);
			}
		} catch (error) {
			console.error('Request Failed:', error);
			throw error;
		}
	}, [
		verificationUserCode,
		userCode,
		gestureData,
		verificationUserCode,
		callbackUrl,
	]);

	const handleCaptureImage = useCallback(() => {
		const captureUserImage = captureImage();
		callFaceLivenessCompletedApi();
		if (userTypeSenderState) {
			compareFaces(captureUserImage);
		}

		if (!customerImageRef.current && captureUserImage) {
			setCustomerImage(captureUserImage);
		}
	}, [customerImageRef.current]);
	// function for future to calculate blink count
	const onResults = useCallback(
		(results?: FaceLandmarkerResult) => {
			try {
				setIsStartFunctionCalled(true);
				const foundGesture = results?.faceBlendshapes?.[0]?.categories.find(
					(shape) =>
						shape?.categoryName === gestureData?.type || shape?.displayName
				);
				if (gestureData && foundGesture && isWebSocketConnectedRef.current) {
					setGestureLevel(parseFloat(foundGesture.score.toFixed(2)));
					if (frequencyCount < (gestureData?.frequency ?? 0)) {
						if (foundGesture.score >= (gestureData?.threshold ?? 0)) {
							setGestureLevel(1);
							setFrequencyCount(frequencyCount + 1);
						}
					} else {
						setFrequencyCount(0);
						const updatedGestureData = localGestureData?.gesture?.map(
							(item: any) =>
								item.id === gestureData?.id
									? { ...item, isSuccess: true }
									: { ...item }
						);
						handleCaptureImage();
						setLocalGestureData((prev: any) => ({
							...prev,
							gesture: updatedGestureData,
						}));
						if (
							localGestureData.gesture.length > stepCount ||
							localGestureData.gesture.length !== stepCount
						) {
							setStepCount(stepCount + 1);
						}
						setTimeout(() => {}, 400);
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.warn('error', error);
			}
		},
		[gestureData, isWebSocketConnectedRef.current, frequencyCount]
	);

	const callFaceLivenessStartedApi = useCallback(async () => {
		const url = callbackUrl;
		const data = {
			event: 'face_liveness',
			timestamp: new Date()?.toISOString(),
			code: userCode ?? verificationUserCode,
			customerId: customer?.id,
			status: 'started',
			payload: {
				gesture: {
					type: gestureData.type,
					threshold: gestureData.threshold,
					frequency: gestureData.frequency,
					id: gestureData.id,
					name: gestureData.name,
				},
			},
		};

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});

			if (response) {
				const result = await response?.json();
				console.log('API Response:', result);
				return result;
			} else {
				console.error('API Error:', response);
				throw new Error(response);
			}
		} catch (error) {
			console.error('Request Failed:', error);
			throw error;
		}
	}, [
		verificationUserCode,
		userCode,
		gestureData,
		verificationUserCode,
		callbackUrl,
	]);

	useEffect(() => {
		if (gestureData.type) {
			callFaceLivenessStartedApi();
		}
	}, [gestureData?.stepId]);

	useEffect(() => {
		// this condition is for send status "started" as a gesture is getting start
		if (
			gestureData.stepId &&
			stepCount <= localGestureData.gesture.length + 1 &&
			isWebSocketConnected &&
			gestureData.status === livenessStatus.PENDING
		) {
			livenessPatchStatus(STARTED, gestureData.stepId);
		}

		const reversedData = [...localGestureData.gesture].reverse();
		const getCompleteGestureData = reversedData.find(
			(gesture: any) => gesture?.isSuccess
		);

		// thia condition check if a gesture is complete then send status "completed"
		if (
			getCompleteGestureData &&
			stepCount <= localGestureData.gesture.length + 1
		) {
			livenessPatchStatus(COMPLETED, getCompleteGestureData.stepId, true);
		}
	}, [stepCount, isWebSocketConnected]);

	// This is function called after webauth response
	const handleSuccess = useCallback(() => {
		handleStopCamera();
		setActiveScreen('loading-results');
	}, [customerImage, livenessGestureInfo]);

	const handleAuthenticateUser = useCallback(async () => {
		// Here we are preventing call unnessary call for webauth
		if (WebAuthRef.current < 1) {
			WebAuthRef.current = WebAuthRef.current + 1;
			try {
				// Here we checking the biometric support
				const isBiometricAvailable = IsBiomentricSupported();
				if (
					window.self !== window.top ||
					// /localhost/gi.test(window.location.origin) ||
					!isBiometricAvailable
				) {
					handleSuccess();
				}
				const registerPayload = {
					email: user?.email_id ?? '123456****',
					id: customer?.id ?? '123456****',
					displayName: user?.name ?? '123456****',
				};
				return registerNewCredentials(handleSuccess, registerPayload);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('error', error);
				setIsWebAuthSuccessfull(true);
				// If device is not supported web auth then we are bye passing here
				handleSuccess();
			}
		}
	}, [user, WebAuthRef.current, customer]);

	useEffect(() => {
		// Here this will go in If user all gesture performe done
		if (!gestureData.type) {
			setIsLiveNessDone(true);
			handleAuthenticateUser();
			closeRequestToServer();
			return;
		}
		const videoBlendShapes = document.getElementById('video-blend-shapes');
		const video = document.getElementById('webcam') as HTMLVideoElement;
		const canvasElement = document.getElementById(
			'output_canvas'
		) as HTMLCanvasElement;

		// Calling functiin to stream mask and video
		start({
			video,
			canvas: canvasElement,
			blendShapes: videoBlendShapes,
			onResults,
		});
	}, [frequencyCount, gestureData.type]);

	// Function for checking device
	const isMobile = useCallback(() => {
		if (window.innerWidth <= screenWidth) {
			return window.innerWidth - 30;
		} else return screenWidth;
	}, []);

	const getVideoHeight = () => {
		return window.innerWidth > screenWidth ? 360 : window.innerHeight / 2;
	};

	const getImageHeight = () => {
		return window.innerWidth > screenWidth ? 360 : window.innerWidth - 20;
	};

	// Function to render the gesture information
	const renderGestureInfo = useMemo(() => {
		const { name, frequency, threshold } = gestureData || {};
		const { MIN, MID, MAX } = GESTURE_LEVEL;
		const getGestureColor =
			gestureLevel < (threshold ?? 0) / 2
				? MIN
				: gestureLevel < (threshold ?? 0)
				  ? MID
				  : MAX;
		if (name && frequency) {
			return (
				<div className="gesture_information">
					<div className="geture-type-label">{`${name} ${frequencyCount} of ${frequency}`}</div>
					<div className="gesture-progress-wrapper">
						<div
							className={`gesture-progress-${getGestureColor}`}
							style={{ width: `${gestureLevel * 100}%` }}
						/>
					</div>
				</div>
			);
		} else {
			return <></>;
		}
	}, [frequencyCount, gestureLevel]);

	// Function to render the gesture refernce label
	const renderRefInfolabel = useMemo(() => {
		return <div className="ref-video-label">{REFERENCE_VIDEO_TEXT}</div>;
	}, []);

	// Function to render the gesture refernce label
	const renderRefInfoStepCount = useMemo(() => {
		return (
			<div className="ref-video-step-count">
				{`Step ${stepCount} of ${localGestureData.gesture.length}`}
			</div>
		);
	}, [stepCount, localGestureData.gesture]);

	// Canvas rendering
	const renderCanvas = useMemo(() => {
		return <CanvasWrapper />;
	}, []);

	const handleLoadedData = () => {
		setLoading(false);
	};

	const handleLoadStart = () => {
		setLoading(true);
	};

	const videoLoading = useMemo(() => {
		if (loading) {
			return (
				<div className="video-loads-loader">
					<Loader type="loader" dimension={40} />
				</div>
			);
		}
		return <></>;
	}, [loading]);

	return (
		<>
			{!IsSentSignalWebRTC ? (
				<div className="liveness-wrapper">
					{(!loaded || !isWebSocketConnected) && <FaceSetupModal />}
					<div
						id="liveView"
						style={{
							display: loaded && isWebSocketConnected ? 'flex' : 'none',
						}}
						className="videoView"
					>
						{/* Video Portion  */}
						<div
							style={{
								position: 'relative',
								width: isMobile(),
								background: 'white',
								height: getVideoHeight(),
								marginBottom: '10px',
							}}
						>
							<video
								id="webcam"
								className="liveness-video"
								autoPlay
								playsInline
								style={{
									width: isMobile(),
									height: getVideoHeight(),
									display: loaded && isWebSocketConnected ? 'block' : 'none',
								}}
							></video>
							{loaded && isWebSocketConnected && renderGestureInfo}
							{/* Canvas video streaming   */}
							{renderCanvas}
						</div>
						{/* Reference image and video */}
						<div
							className="gesture-type-box"
							style={{
								backgroundColor: '#e6e6e6',
								width: isMobile(),
								height: getImageHeight(),
								pointerEvents: 'none',
							}}
						>
							{gestureData?.video !== 'NA' ? (
								<>
									<video
										src={gestureData?.video}
										className="gesture-type-box__video"
										controls={false}
										autoPlay
										playsInline
										loop
										muted
										width={isMobile()}
										height={getImageHeight()}
										onLoadedData={handleLoadedData}
										onLoadStart={handleLoadStart}
									/>
									{videoLoading}
								</>
							) : (
								<img
									src={gestureData?.image}
									width={isMobile()}
									height={getImageHeight()}
								/>
							)}
							{renderRefInfolabel}
							{renderRefInfoStepCount}
						</div>
					</div>
				</div>
			) : (
				<>
					<LoadingSection />
				</>
			)}
		</>
	);
};
