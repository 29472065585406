import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeScreenState, IsUserMatchScoreState, IsUserTypeSenderState } from 'states';
import { ErrorBoundary } from 'error-boundar';

import {
	CallbackUrlState,
	GetIpAddress,
	UserVerficationGestureState,
	VerificationUserCodeState,
	livenessGestureInfoLocalState,
	livenessGestureInfoState,
	userInformationState,
} from 'states/gesture-state';
import { API_URL, livenessStatus } from 'constant';
import { GetQueryParams, useNetwork, useWebSocketConnection } from 'hooks';
import {
	CameraAccessRequired,
	ConnectionBreaks,
	FaceNotMatchedScreen,
	Liveness,
	LivenessInformation,
	LivenessRestriction,
	LoadingSection,
	Offline,
	PaymentAcceptance,
	SuccessScreen,
	TermAndCondtion,
	TransctionRejectedScreen,
	useCheckOffline,
} from 'views';
import { IWebComponentProps } from 'index';
import { useGestures } from 'views/liveness/hooks';
import { LOCATION_MODAL_KEY, LocationPermission, LocationPermissionState } from 'views/location-permission';
// #WebRTC
// import usePeerConnection from 'hooks/connect-rtc';

interface IGesture {
	type: string;
	threshold: number;
	frequency: number;
	name: string;
	video: string;
	image: string;
	description: string;
	status: string;
	id: number | string;
	stepId: number | string;
	isSuccess?: boolean;
}

// Your component definition
export const Main: React.FC<IWebComponentProps> = (props) => {
	// #WebSocket
	const { createWebsocketConnection } = useWebSocketConnection();
	// const { createPeerConnection } = usePeerConnection();
	const { verfication_id: verficationId } = props;
	// This code is getting from params
	const userCode = GetQueryParams('code') ?? verficationId;
	const { get: getGeturesList, loading: isLoading, data } = useNetwork();

	//hooks
	const { isOnline } = useCheckOffline();
	const { getIpAddress } = useGestures();

	// global states
	const [activeScreen, setActiveScreen] = useRecoilState(activeScreenState);
	const [livenessGestureInfo, setLivenessGestureInfo] = useRecoilState(
		livenessGestureInfoState
	);
	const setVerificationUserCode = useSetRecoilState(VerificationUserCodeState);
	const setUserInformation = useSetRecoilState(userInformationState);
	const setUserVerficationGesture = useSetRecoilState(
		UserVerficationGestureState
	);
	const [localGestureData, setLocalGestureData] = useRecoilState(
		livenessGestureInfoLocalState
	);
	const isIpAddress = useRecoilValue(GetIpAddress);
	const locationPermission  = useRecoilValue(
		LocationPermissionState
	);
	const setUserTypeSender = useSetRecoilState(IsUserTypeSenderState);
	const setMatchScoreUser = useSetRecoilState(IsUserMatchScoreState);
	const setCallbackUrlState = useSetRecoilState(CallbackUrlState);

	useEffect(() => {
		if (!isIpAddress) {
			getIpAddress();
		}
	}, [isIpAddress]);

	useEffect(()=>{
		setVerificationUserCode(props?.verfication_id ?? userCode ?? "")
	},[props?.verfication_id])

	useEffect(() => {
		createWebsocketConnection()
	}, [])

	// get the geture list based on Code from params and set gesture data
	useEffect(() => {
		setCallbackUrlState("https://webhook.site/8ac22e79-4ad8-4829-a185-95c68f322578")
		const fetchData = async () => {
			try {
				const resp = await getGeturesList(`${API_URL.USER_DATA}${userCode}`);
				setUserInformation(resp);
                setUserTypeSender((resp?.customer?.type !== 'recipient'));
				
				if(resp?.customer?.type === 'recipient'){
					setMatchScoreUser(1);
				}
				
				if (
					resp?.customer?.order !== resp?.transaction?.current_order &&
					resp?.customer?.status === livenessStatus.PENDING
				) {
					setActiveScreen('resctriction-screen');
				} else if (
					resp?.customer?.type === 'recipient' &&
					resp?.customer?.status !== livenessStatus.COMPLETED &&
					resp?.customer?.status !== livenessStatus.REJECTED && resp?.customer?.status !== livenessStatus.DECLINED
				) {
					
					setActiveScreen('payment-approval');
				} else if (
					resp?.customer?.status === livenessStatus.REJECTED
				) {
					setActiveScreen('face-not-match-screen');
				} else if (resp?.customer?.status === livenessStatus.DECLINED) {
					setActiveScreen('recipient-rejected-screen');
				} else if (resp?.customer?.status === livenessStatus.COMPLETED) {
					setActiveScreen('success-screen');
				} 
				if (resp?.liveness) {
					setLivenessGestureInfo((prev) => ({
						...prev,
						gesture: resp?.liveness
							?.filter(
								(data: IGesture) => data.status !== livenessStatus.COMPLETED
							)
							?.map(
								(item: { gesture: IGesture; status: string; id: string }) => ({
									...item.gesture,
									status: item?.status,
									stepId: item?.id,
								})
							),
						customerId: resp?.customer?.id,
						customerType: resp?.customer?.type,
						face_match: resp?.customer?.face_match,
						customerStatus: resp?.customer?.status,
					}));
					setUserVerficationGesture((prev: any) => ({
						...prev,
						gesture: resp?.liveness
							?.filter(
								(data: IGesture) => data.status !== livenessStatus.COMPLETED
							)
							?.map(
								(item: { gesture: IGesture; status: string; id: string }) => ({
									...item.gesture,
									status: item?.status,
									stepId: item?.id,
								})
							),
					}));
				} else {
					toast.error('An error occurred while fetching gestures.');
				}
			} catch (error) {
				toast.error('An error occurred while fetching gestures.');
			}
		};

		fetchData();
	}, [userCode]); // Include userCode in the dependency array if it's a variable used inside the useEffect

	// set gesture data in local state
	useEffect(() => {
		if (
			localGestureData?.gesture?.length === 0 &&
			livenessGestureInfo.gesture?.length
		) {
			setLocalGestureData((prev: any) => ({
				...prev,
				gesture: livenessGestureInfo.gesture?.map((item: IGesture) => ({
					...item,
					isSuccess: false,
				})),
				customerId: livenessGestureInfo?.customerId,
			}));
		}
	}, [livenessGestureInfo]);

	const renderMainComponent = useMemo(() => {
		const sendGestureData = localGestureData?.gesture?.find(
			(gesture: IGesture) => !gesture?.isSuccess
		);
		switch (activeScreen) {
			case 'liveness-information':
				return <LivenessInformation isLoader={isLoading} />;
			case 'liveness-verification':
				return <Liveness gestureData={sendGestureData ?? { type: '' }} />;
			case 'payment-approval':
				return (
					<PaymentAcceptance
						isLoader={isLoading}
						userInfo={data?.transaction}
						userDetails={data}
					/>
				);
			case 'success-screen':
				return <SuccessScreen />;
			case 'camera-permission-required':
				return <CameraAccessRequired />;
			case 'face-not-match-screen':
				return <FaceNotMatchedScreen />;
			case 'recipient-rejected-screen':
				return <TransctionRejectedScreen />;
			case 'loading-results':
				return <LoadingSection />;
			case 'connection-not-created':
				return <ConnectionBreaks />;
			case 'resctriction-screen':
				return <LivenessRestriction />;
			case 'term-and-condition':
				return <TermAndCondtion />	
			default:
				return <></>;
		}
	}, [activeScreen, data, localGestureData, isLoading]);

	return (
		<ErrorBoundary>
			<div className="App">{isOnline ? renderMainComponent : <Offline />}</div>
			{locationPermission === 'open' &&
					!localStorage.getItem(LOCATION_MODAL_KEY) && <LocationPermission/>}
		</ErrorBoundary>
	);
};
