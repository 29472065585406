import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeScreenState, IsUserMatchScoreState } from 'states';
import { Image, Loader } from '@storybook';
import { LoadingScreenMessage, livenessStatus } from 'constant';
// import { useNetwork } from 'hooks';
import { useGestures } from './hooks';
import { CustomerImage, userInformationState } from 'states/gesture-state';
import { IsWebAuthSuccessfullState } from 'hooks/web-authn/state';
import './liveness.scss';

export const LoadingSection = () => {
	// Recoils
	const getCustomerImage = useRecoilValue(CustomerImage);
	const userInformations = useRecoilValue(userInformationState);
	const IsWebAuthSuccessfull = useRecoilValue(IsWebAuthSuccessfullState);
	const userMatchScore = useRecoilValue(IsUserMatchScoreState);

	const { customer } = userInformations ?? {};
	// Hooks
	const { customerPatchStatus } = useGestures();

	const setActiveScreen = useSetRecoilState(activeScreenState);
	// const { get: getFaceMatchResults } = useNetwork();

	useEffect(() => {
		const threshold = 0.7;
		const GetFaceResult = setInterval(async () => {
			try {
				// const resp = await getFaceMatchResults(
				// 	`${API_URL.FACE_MATCH_RESULT}${customer?.id}`
				// );
				// eslint-disable-next-line no-console
				// console.log("resp",resp);
				// eslint-disable-next-line no-console
				console.log("userMatchScore",userMatchScore);
				if (userMatchScore !== -1) {
					if (userMatchScore < threshold) {
						customerPatchStatus(
							livenessStatus.REJECTED,
							customer?.id.toString() ?? '',
							getCustomerImage
						);
						setActiveScreen('face-not-match-screen');
					} else {
						customerPatchStatus(
							livenessStatus.COMPLETED,
							customer?.id.toString() ?? '',
							getCustomerImage
						);
						setActiveScreen('success-screen');
					}
					clearInterval(GetFaceResult);
					return;
				}

				// if (!resp?.face_match || resp?.match_score !== null) {
				// 	if (resp?.match_score !== null) {
				// 		if (resp.match_score < threshold) {
				// 			customerPatchStatus(
				// 				livenessStatus.COMPLETED,
				// 				customer?.id.toString() ?? '',
				// 				getCustomerImage
				// 			);
				// 			clearInterval(GetFaceResult);
				// 		} else {
				// 			customerPatchStatus(
				// 				livenessStatus.REJECTED,
				// 				customer?.id.toString() ?? '',
				// 				getCustomerImage
				// 			);
				// 			setActiveScreen('face-not-match-screen');
				// 			clearInterval(GetFaceResult);
				// 		}
				// 	} else {
				// 		customerPatchStatus(
				// 			livenessStatus.COMPLETED,
				// 			customer?.id.toString() ?? '',
				// 			getCustomerImage
				// 		);
				// 	}

				// 	//Temp change for bypass match score @Anuj
				// 	if (resp.status !== 'started') {

				// 		if (IsWebAuthSuccessfull) {
				// 			setActiveScreen('success-screen');
				// 		} else {
				// 			setActiveScreen('face-not-match-screen');
				// 		}
				// 		clearInterval(GetFaceResult);
				// 	} else if (resp.statusCode !== 200) {
				// 		if (IsWebAuthSuccessfull) {
				// 			setActiveScreen('success-screen');
				// 		}
				// 	}
				// }
			} catch (error) {
				if (IsWebAuthSuccessfull) {
					setActiveScreen('success-screen');
				}
				// toast.error('An error occurred while fetching gestures.');
			}
		}, 1000);
	}, [IsWebAuthSuccessfull,customer,userMatchScore]);

	return (
		<div className="loading-wrapper">
			<div className="loading-wrapper__box">
				<Image fileName="verify-identification.svg" />
				<p className="loading-wrapper__heading">
					<b>{LoadingScreenMessage.Heading}</b>
				</p>
				<div className="loading-wrapper__message">
					{LoadingScreenMessage.SUBHEADING}
				</div>
				<Loader type="loader" dimension={32} />
			</div>
		</div>
	);
};
